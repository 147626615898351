import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { textTitle, resetButtonStyles } from 'layout/mixins';
import { getColor } from 'layout/theme';
import { ReactComponent as CheckCircle } from 'assets/icons/check-circle.svg';
import { ReactComponent as ExclamationCircle } from 'assets/icons/exclamationCircle.svg';
import { ReactComponent as CloseFilled } from 'assets/icons/close-filled.svg';
import { deviceMedia, toolBarHeight, headerHeight } from 'appConstants';
import { Notification, NotificationAppearance, NotificationType } from 'store/notifications';

const Toast = styled.div<{ type: NotificationType; customStyles?: string }>`
  position: absolute;
  width: 100%;
  top: ${toolBarHeight + headerHeight}px;
  left: 0;
  z-index: 999;
  ${({ type }) => (type === 'permanentToast' ? 'height: 100%; background: rgba(79, 79, 79, 0.9);' : '')};
  ${({ customStyles }) => customStyles || ''};
  @media ${deviceMedia.mobile} {
    position: fixed;
    top: 0;
  }
`;

const StyledExclamationCircle = styled(ExclamationCircle)`
  color: rgb(255, 255, 255);
  fill: rgb(255, 255, 255);
  width: 30px;
  height: 30px;
`;

const Message = styled.div<{ isBoxShadow?: boolean }>`
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  width: 600px;
  margin-left: -300px;
  background: ${props => getColor(props, 'white')};
  ${({ isBoxShadow }) => (isBoxShadow ? 'box-shadow: 0 1px 4px 1px rgba(0,0,0,0.5);' : '')};

  @media ${deviceMedia.mobile} {
    width: 100%;
    margin: 0;
    left: 0;
    right: 0;
  }
`;

const Icon = styled.div<{ statusColor: string }>`
  display: flex;
  align-items: center;
  min-height: 100%;
  background-color: ${({ statusColor }) => statusColor};
  padding: 15px;

  g {
    fill: ${props => getColor(props, 'white')};
  }
`;

const CloseButton = styled.button`
  ${resetButtonStyles};
  position: absolute;
  right: 15px;
  top: 15px;
  height: 20px;
  width: 20px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const Title = styled.div`
  ${textTitle};
  text-transform: uppercase;
`;

export const Body = styled.div`
  padding: 15px 30px;
`;

interface OwnProps {
  notification: Notification;
}

export const NotificationWrapper = (props: OwnProps) => {
  const { notification } = props;
  const {
    type,
    title,
    text,
    appearance = NotificationAppearance.success,
    customOverlayStyles,
    isInnerHtmlText = false
  } = notification;
  const theme = useContext(ThemeContext);

  const getStatusColor = () => {
    switch (appearance) {
      case NotificationAppearance.success:
        return theme.configurableColors.color4;
      case NotificationAppearance.error:
        return theme.baseColors.red;
      default:
        return NotificationAppearance.success;
    }
  };

  return (
    <Toast data-test-id="toast-main-container" type={type} customStyles={customOverlayStyles}>
      <Message isBoxShadow={type === 'toast'}>
        <Icon statusColor={getStatusColor()}>
          {appearance === 'success' && <CheckCircle data-test-id="success-icon" />}
          {appearance === 'error' && <StyledExclamationCircle data-test-id="error-icon" />}
        </Icon>
        <Body>
          <Title data-test-id="toast-title">{title}</Title>
          {isInnerHtmlText ? (
            <p data-test-id="toast-text" dangerouslySetInnerHTML={{ __html: text }} />
          ) : (
            <p data-test-id="toast-text">{text}</p>
          )}
        </Body>
        {type === 'permanentToast' && (
          <CloseButton onClick={() => {}}>
            <CloseFilled />
          </CloseButton>
        )}
      </Message>
    </Toast>
  );
};
