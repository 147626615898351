import { createAction, createAsyncAction } from 'typesafe-actions';
import { Features } from '../../page-elements/RewardsDataFetcher';
import {
  CRMStatusResponseAction,
  PromoSettingsCancel,
  PromoSettingsRequestAction,
  PromoSettingsResponseAction,
  PromosResponse,
  SavePromosRequestAction,
  SavePromosResponse,
  LoyaltyMonthlySummaryResponse,
  LoyaltyRequest,
  LoyaltyTotalResponse,
  LoyaltyRewardsResponse,
  FetchActiveCoreProgramPayload,
  FetchActiveCoreProgramResponse
} from './types';

const init = createAction('@rewards/init', action => (payload: Features) => action(payload));

const promos = createAsyncAction(
  '@rewards/promos/fetch',
  '@rewards/promos/fetch/success',
  '@rewards/promos/fetch/error'
)<void, PromosResponse, any>();

const savePromos = createAsyncAction(
  '@rewards/promos/save',
  '@rewards/promos/save/success',
  '@rewards/promos/save/error'
)<SavePromosRequestAction, SavePromosResponse, void>();

const promoSettings = createAsyncAction(
  '@rewards/promo-settings/fetch',
  '@rewards/promo-settings/fetch/success',
  '@rewards/promo-settings/fetch/error'
)<PromoSettingsRequestAction, PromoSettingsResponseAction, any>();

const promoSettingsSetDefault = createAction(
  '@rewards/promo-settings/setDefault',
  action => (payload: PromoSettingsCancel) => action(payload)
);

const promoSettingsDiscardChanges = createAction('@rewards/promo-settings/discardDefault', action => () => action());

const crmData = createAsyncAction(
  '@rewards/crm-data/fetch',
  '@rewards/crm-data/fetch/success',
  '@rewards/crm-data/fetch/error'
)<void, CRMStatusResponseAction, void>();

const toggleCoreConfirmModal = createAction(
  '@rewards/toggleCoreConfirmModal',
  action => (payload: boolean) => action(payload)
);

const toggleLoyaltyCalculator = createAction(
  '@rewards/calculator/toggleLoyaltyCalculator',
  action => (payload: boolean) => action(payload)
);

const toggleCoreSuccessModal = createAction(
  '@rewards/toggleCoreSuccessModal',
  action => (payload: boolean) => action(payload)
);

const toggleFreezeModal = createAction('@rewards/toggleFreezeModal', action => (payload: boolean) => action(payload));

const toggleRewardsAutomationSuccessModal = createAction(
  '@rewards/toggleRewardsAutomationSuccessModal',
  action => (payload: { status: boolean; campaign: string; mode: 'activate' | 'save' }) => action(payload)
);

const toggleRewardsAutomationAreYouSureModal = createAction(
  '@rewards/toggleRewardsAutomationAreYouSureModal',
  action => (payload: boolean) => action(payload)
);

const toggleRewardsAutomationResetModal = createAction(
  '@rewards/toggleRewardsAutomationResetModal',
  action => (payload: boolean) => action(payload)
);

const loyaltyMonthlySummary = createAsyncAction(
  '@loyalty/monthlySummary/fetch',
  '@loyalty/monthlySummary/fetch/success',
  '@loyalty/monthlySummary/fetch/error'
)<LoyaltyRequest, LoyaltyMonthlySummaryResponse, any>();

const loyaltyRewards = createAsyncAction(
  '@loyalty/rewards/fetch',
  '@loyalty/rewards/fetch/success',
  '@loyalty/rewards/fetch/error'
)<LoyaltyRequest, LoyaltyRewardsResponse, any>();

const loyaltyTotal = createAsyncAction(
  '@loyalty/total/fetch',
  '@loyalty/total/fetch/success',
  '@loyalty/total/fetch/error'
)<LoyaltyRequest, LoyaltyTotalResponse, any>();

const fetchActiveCoreProgram = createAsyncAction(
  '@loyalty/fetchActiveCoreProgram/fetch',
  '@loyalty/fetchActiveCoreProgram/success',
  '@loyalty/fetchActiveCoreProgram/error'
)<FetchActiveCoreProgramPayload, FetchActiveCoreProgramResponse, any>();

const updateIsFirstTimeLoaded = createAction(
  '@rewards/updateIsFirstTimeLoaded',
  action => (payload: boolean) => action(payload)
);
export const rewardsActions = {
  init,
  promos,
  savePromos,
  promoSettings,
  promoSettingsSetDefault,
  promoSettingsDiscardChanges,
  crmData,
  toggleCoreConfirmModal,
  toggleCoreSuccessModal,
  toggleRewardsAutomationSuccessModal,
  toggleRewardsAutomationAreYouSureModal,
  toggleRewardsAutomationResetModal,
  loyaltyMonthlySummary,
  loyaltyRewards,
  loyaltyTotal,
  toggleLoyaltyCalculator,
  fetchActiveCoreProgram,
  toggleFreezeModal,
  updateIsFirstTimeLoaded
};
