export enum NotificationAppearance {
  error = 'error',
  success = 'success'
}

export type AppearanceTypes = keyof typeof NotificationAppearance;

export interface Notification {
  id?: string;
  type: NotificationType;
  title: string;
  text: string;
  appearance?: AppearanceTypes;
  customOverlayStyles?: string;
  isInnerHtmlText?: boolean;
}

export type NotificationType = 'modal' | 'toast' | 'permanentToast';
export interface NotificationsMap {
  [key: string]: Notification;
}
