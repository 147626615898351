import { put, select, takeLatest } from 'redux-saga/effects';
import { uniq } from 'lodash';
import { getType } from 'typesafe-actions';
import { LOCATION_CHANGE, LocationChangeAction, push } from 'connected-react-router';
import { ERROR_PAGES } from 'appConstants';
import { googleAnalytics } from 'services/googleAnalytics';
import { navigationActions } from './actions';
import { AppState } from '../rootReducer';
import { Page } from '../settings/layout';

export function* menuSagas() {
  yield takeLatest(
    getType(navigationActions.receivePages),
    function* ({ payload }: ReturnType<typeof navigationActions.receivePages>) {
      const state: AppState = yield select();
      const { pathname } = state.router.location;
      const { pages } = state.appMenu;
      const { merchantPagesStatus } = payload;

      if (pathname === '/') {
        const subscriptions = uniq(
          Object.values(merchantPagesStatus).reduce((all, current) => [...all, ...current], [])
        );
        const [firstActivePage] = pages.filter((page: Page) => subscriptions.includes(page.pageId));
        if (!!firstActivePage && firstActivePage !== pages[0]) {
          return yield put(push(firstActivePage.path));
        }
      }

      return yield put(navigationActions.setActivePage(state.router.location.pathname));
    }
  );

  yield takeLatest(LOCATION_CHANGE, function* (action: LocationChangeAction) {
    const nextPathName = action.payload.location.pathname;
    if (action.payload.action === 'PUSH' || action.payload.action === 'POP') {
      yield put(navigationActions.setActivePage(nextPathName));
    }
    if (action.payload.action === 'REPLACE' && ERROR_PAGES.includes(nextPathName)) {
      googleAnalytics.sendPageView(nextPathName);
    }
  });
}
